


























import Vue from "vue";

export default Vue.extend({
  name: "EasyCounter",
  inheritAttrs: false,
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange($event: any) {
      const valNumber = parseInt($event.target.value);
      if (!valNumber) this.$emit("input", 1);
      else if (valNumber > 9999) this.$emit("input", 9999);
      else if (valNumber < 1) this.$emit("input", 1);
      else this.$emit("input", valNumber);
    },
    onPlus() {
      this.$emit("input", +this.$attrs.value + 1);
    },
    onMinus() {
      const data = +this.$attrs.value <= 1 ? 1 : +this.$attrs.value - 1;
      this.$emit("input", data);
    },
  },
});
